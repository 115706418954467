<template>
  <div class="home">
     <v-card class="mx-auto" max-width="400" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">gpstrack.dataman.ro</div>
          <v-list-item-title class="headline mb-1">GPS Track</v-list-item-title>
          <v-list-item-subtitle>Monitorizare flotă auto</v-list-item-subtitle>
        </v-list-item-content>
  
        <v-list-item-avatar tile size="120">
        <v-icon large color="green">directions_car</v-icon><br/>
        </v-list-item-avatar>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
